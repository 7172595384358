import React from "react";
import "./AgradecimentoPage.css";

const AgradecimentoPage = () => {

  return (
    <div className="container">
        <h2>Obrigado por me avaliar!</h2>
      </div>
  );
};

export default AgradecimentoPage;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AvaliacaoPage from "./pages/AvaliacaoPage";
import AgradecimentoPage from "./pages/AgradecimentoPage"; 
import Respostas from "./pages/Respostas";

const nomesAtendentes = [
  "Aline",
  "Chris",
  "Duda",
  "Yuri",
  "Giovana",
  "Bruno",
  "Renan",
  "Richard",
  "Donnes",
  "Mariana",
];

const App = () => {
  return (
    <Router>
      <Routes>
        {nomesAtendentes.map((atendente) => (
          <Route
            key={atendente}
            path={`/${atendente.toLowerCase()}`}
            element={<AvaliacaoPage atendente={atendente} />}
          />
        ))}
        <Route path="/obrigado" element={<AgradecimentoPage />} /> {/* Nova rota */}
        <Route path="/respostas" element={<Respostas />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

const NotFoundPage = () => {
  return (
    <div>
      Digite o nome do(a) Donner aqui acima na barra de endereços, como por exemplo: avaliacao.donnes.com.br/duda
    </div>
  );
};

export default App;

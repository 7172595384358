import React from "react";
import "./NPS.css";

const NPS = ({ quantidadeOpcoes, handleOpcaoClick, opcaoSelecionada }) => {
  const opcoes = Array.from({ length: quantidadeOpcoes }, (_, index) => index);

  return (
    <div className="nps-container">
      <p className="nps-text">Com base em sua experiência, em uma escala de 0 a 10, qual é a chance de você indicar a Donnes?</p>
      <div className="nps-opcoes">
        {opcoes.map((opcao) => (
          <div
            key={opcao}
            className={`nps-option ${opcaoSelecionada === opcao ? "selected" : ""}`}
            onClick={() => handleOpcaoClick(opcao)}
          >
            <span className="nps-option-text">{opcao}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NPS;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Respostas.css";

const Respostas = () => {
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/avaliacoes");
        console.log(response.data);

        // Verificar se response.data é um array válido
        if (!Array.isArray(response.data)) {
          setError(true);
        } else {
          const avaliacoesData = response.data.map((item) => ({
            id: item.id,
            donner: {
              id: item.donnerId,
              nome: item.donnerName,
            },
            avaliacao: {
              nota: item.avaliacaoNota,
              mensagem: item.avaliacaoMensagem,
              nps: item.avaliacaoIndicacao, // Adicionando a propriedade NPS
            },
            dataHora: item.createDate,
          }));

          // Ordenar as avaliações da mais recente para a mais antiga
          avaliacoesData.sort((a, b) => new Date(b.dataHora) - new Date(a.dataHora));

          setAvaliacoes(avaliacoesData);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p className="respostas-message">Carregando...</p>;
  }

  if (error) {
    return (
      <p className="respostas-message">Dados inválidos retornados do servidor.</p>
    );
  }

  return (
    <div className="respostas-container">
      <h1 className="respostas-title">Respostas</h1>
      {avaliacoes.length === 0 ? (
        <p className="respostas-message">Nenhum resultado encontrado.</p>
      ) : (
        <table className="respostas-table">
          <thead>
            <tr>
              <th>Donner</th>
              <th>Nota</th>
              <th>Feedback</th>
              <th>NPS</th> 
              <th>Data da Avaliação</th>
            </tr>
          </thead>
          <tbody>
            {avaliacoes.map((avaliacao) => (
              <tr key={avaliacao.id}>
                <td>{avaliacao.donner.nome}</td>
                <td>{avaliacao.avaliacao.nota}</td>
                <td>{avaliacao.avaliacao.mensagem}</td>
                <td>{avaliacao.avaliacao.nps}</td> 
                <td>{avaliacao.dataHora}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Respostas;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AvaliacaoPage.css";
import Notas from "../cross/Notas";
import NPS from "../cross/NPS";

const AvaliacaoPage = ({ atendente }) => {
  const [nota, setNota] = useState(0);
  const [comentario, setComentario] = useState("");
  const [enviado, setEnviado] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);
  const [chanceIndicacao, setChanceIndicacao] = useState(null);
  const navigate = useNavigate();

  const handleNotaClick = (index) => {
    setNota(index + 1);
    setOpcaoSelecionada(index);
  };

  const handleChanceIndicacaoClick = (index) => {
    setChanceIndicacao(index);
  };

  const handleSubmit = async () => {
    try {
      const dataHora = new Date().toISOString();
      const body = {
        data: {
          nota: nota.toString(),
          indicacao: chanceIndicacao,
          mensagem: comentario,
          donner: atendente,
          dataCriacao: dataHora,
        },
        assunto: "nps",
      };

      const response = await fetch("https://dev-donn-avaliacao-fila.azurewebsites.net/api/salvando-avaliacao-fila?code=io0TezCfK7YbK-3ya1sfJteTUEQwfzwTNwdkBEV5wS_mAzFur-IKvg==", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setEnviado(true);
        navigate("/obrigado");
      } else {
        console.error("Erro ao enviar a resposta. Código de status:", response.status);
      }
    } catch (error) {
      console.error("Erro ao enviar a resposta:", error);
    }
  };

  return (
    <div className="container">
      <div>
        {enviado ? (
          <p>Obrigado por me avaliar!</p>
        ) : (
          <div>
            <img
              className="profile-picture"
              src={`/images/users/${atendente.toLowerCase()}.png`}
              alt={atendente}
            />
            <h3>{atendente}</h3>
            <h2>Avalie sua interação comigo</h2>
            <div className="options">
              <Notas handleNotaClick={handleNotaClick} opcaoSelecionada={opcaoSelecionada} />
            </div>
            <div>
              <NPS
                quantidadeOpcoes={11}
                handleOpcaoClick={handleChanceIndicacaoClick}
                opcaoSelecionada={chanceIndicacao}
              />
            </div>
            <textarea
              placeholder="Deixe um comentário (opcional)"
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
            ></textarea>
            <button disabled={!nota} onClick={handleSubmit}>
              enviar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvaliacaoPage;
import React from "react";

const Notas = ({ handleNotaClick, opcaoSelecionada }) => {
  const opcoes = [
    {
      valor: "Não gostei",
      imagem: "nao_gostei.png",
    },
    {
      valor: "Pode melhorar",
      imagem: "pode_melhorar.png",
    },
    {
      valor: "OK",
      imagem: "ok.png",
    },
    {
      valor: "Bom",
      imagem: "bom.png",
    },
    {
      valor: "Gostei",
      imagem: "gostei.png",
    },
    {
      valor: "Adorei",
      imagem: "adorei.png",
    },
    {
      valor: "Uhuuuuu",
      imagem: "uhuuuuu.png",
    },
  ];

  return (
    <>
      {opcoes.map((opcao, index) => (
        <div
          key={opcao.valor}
          className={`option ${opcaoSelecionada === index ? "selected" : ""}`}
          onClick={() => handleNotaClick(index)}
        >
          <img className="option-img" src={`/images/${opcao.imagem}`} alt={opcao.valor} />
          <span className="option-text">{opcao.valor}</span>
        </div>
      ))}
    </>
  );
};

export default Notas;